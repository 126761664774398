:root{
  /* --maincolor: #003049; */
  --maincolor: #1b2e64;
  --mainlightcolor: #fdfdff;
  --seconddarkcolor: #546a7b;
  /* --secondlightcolor:#c6c5b9; */
  /* --secondlightcolor:#e07a5f; */
  --secondlightcolor:#da9552;
}

#root{
  background-color: var(--maincolor);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: var(--mainlightcolor);
}
a:link {
  color: var(--mainlightcolor);
}
a:visited {
  color: var(--mainlightcolor);
}
a:hover {
  color: var(--secondlightcolor);
}
a:active {
  color: var(--secondlightcolor);
}

/* Text Size */

h2 {
  font-weight: normal !important;
}

h3 {
  font-weight: normal !important;
}

h4 {
  font-weight: normal !important;
}

h5 {
  font-weight: normal !important;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 1.2em;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.8em;
  }

  h4 {
    font-size: 0.6em;
  }

  h5 {
    font-weight: normal !important;
  }
}


.nav-tabs .nav-link.active {
  border-bottom: solid 4px var(--secondlightcolor) !important;
}

#off-canva{
  background-color: var(--maincolor);
}

#link-color{
  color: var(--seconddarkcolor) !important;
}

/* Navigation Bars */

.navbar .navbar-brand {
  margin-left: 15px;
}

.white-section{
  background-color: var(--mainlightcolor);
}

.accent-section{
  background-color: var(--secondlightcolor);
}

.second-accent-section{
  background-color: var(--seconddarkcolor);
}

#test{
  background-color: blue !important;
}


/* Home Properties */

.home{
  background-color: var(--secondlightcolor);
  width: 100%;
  /* height:2550px; */
  height: 100%;
  position: relative;
  text-align: center;
}

#full-width-image {
  width: 100%;
  height: 21%;
  object-fit: cover;
  object-position: bottom;
}

.home_title{
  /* font-size:400%; */
  width: 100%;
  height: 300px;
  padding: 100px;
  position:absolute;
  top:8px;
  right:16px;
  color:white;
  align-items: left;
}

#welcome{
  position: absolute;
  left: 10%;
  top:10%;

}

.platforms{
  position: relative;
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 20%;
}

.home_message_textbox{
  position: absolute;
  margin-left: 0px;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  width: 50%;
  height: 85%;
  background-color: var(--secondlightcolor);

}

.home_message_textbox_content{
  text-align: center;
  /* font-size: large; */
  margin-left: 5%;
  margin-top: 5%;
  width: 75%;
  position: relative;
}

.home_message_image{
  position: absolute;
  margin-left: -5%;
  margin-top: 4%;
  z-index: 3;
  width: 40%;
  height: 75%;
}

.groupe_home_icon{
  width: 100%;
  margin-top: 3%;
  margin-left: 20%;
  align-items: center;
  position: relative;
}

.home_icon{
  float: left;
  margin-left: 10%;
  position: relative;
}



/* Overall Properties */



/* About Properties */


.tri-div-section{
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.tri-div-text{
  background-color: inherit;
  text-align: left;
  /* font-size: medium; */
  font-style: oblique;
}

#tri-div-section_1_2{
  width: 33%;
  float: left;
  background-color: inherit;
}

#tri-div-section_3{
  width: 33%;
  float: right;
  background-color: inherit;
}





/* Edition Properties */



/* House Properties */




/* Bootstrap Icon */

.bi::before {
  display: inline-block;
  content: "";
  vertical-align: -.125em;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}








.connect{
  position: relative;
  background-color: var(--maincolor);
  width: 100%;
  height: 20%;
}

.annoucement{
  position: relative;
  background-color: var(--maincolor);
  width: 100%;
  height: 20%;
}

#annoucement_image{
  margin-top: 20%;
  width: 90%;
  height: 90%;
}

.testimonies{
  position: relative;
  background-color: var(--seconddarkcolor);
  width: 100%;
  height: 20%;
}

.text-home-bottom-left{
  /* font-size: large; */
  width: 100%;
  height: 300px;
  position:absolute;
  bottom:8px;
  left:16px;
}



.section_title{
  /* font-size: x-large; */
  width: 100%;
  height: 300px;
  padding: 10px;
  position:absolute;
  top:8px;
  right:16px;
  color:var(--secondlightcolor);
}

.section_description{
  /* font-size: medium; */
  width: 100%;
  height: 300px;
  padding: 50px;
  position:absolute;
  top:8px;
  right:16px;
  color:white;
}




/* End Home Properties */



/* Contacts et don */


.icon-style{
  color: var(--maincolor);
  font-size: 2em;
}

.important-info{
  background-color: var(--maincolor);
  color: var(--mainlightcolor);
  margin-left: 1%;
  margin-right: 1%;
  width: 100%;
  /* font-size: x-large; */
}

.double-div-contact{
  width: 100%;
  padding: 5%;
  position:relative;
  height: 17%;
  margin-bottom: 0%;
  padding-bottom: 0%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.double-div-contact-1{
  width: 50%;
  float: left;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* text-size-adjust: 10%; */
  text-align: center;
  /* font-size: xx-large; */
}

.double-div-contact-1 img{
  width: 50%;
  height: 70%;
  max-width: 100%;
  object-fit: contain;
  padding: 0;
  margin: 5%;
  margin-bottom: 0%;
}

.double-div-contact-2{
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.paypal-info{
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 10%;
  padding-bottom: 0%;
  padding-top: 0%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.double-div-contact-2 img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.tri-div-contact{
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: x-large; */
  padding: 10%;
}

.tri-div-contact-1-2{
  width: 33%;
  float: left;
  background-color: inherit;
}

.tri-div-contact-3{
  width: 33%;
  float: right;
  background-color: inherit;
}

.subtitles-contact{
  height: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 0%;
  padding-top: 30%;
  padding-bottom: 0%;
  background-color: var(--mainlightcolor);
  color: var(--maincolor);
}

#rejoindre{
  height: 10% !important;
  padding: 10% !important;
}

/* End Contacts et don */

/* Profils et Témoignage */

.picture-withness{
  width: 500px;
  height: 300px;
  max-width: 100%;
  object-fit: contain;
}

.spacing{
  padding-bottom: 10%;
  background-color: var(--mainlightcolor);
}

.description_testimonies{
  /* font-size: x-large; */
  padding: 10%;
  color: var(--mainlightcolor);
}

.double-div-testimonies{
  width: 100%;
  padding: 5%;
  position:relative;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  padding-top: 15%;
  padding-bottom: 10%;
}

.double-div-testimonies-1{
  width: 50%;
  float: left;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* text-size-adjust: 10%; */
  text-align: center;
  /* font-size: xx-large; */
}

.double-div-testimonies-1 img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.double-div-testimonies-2{
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* text-size-adjust: 10%; */
  text-align: center;
  /* font-size: large; */
}

.double-div-testimonies-2 img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.first-part-testimony{
  width: 100%;
  height: auto;
  justify-content: left;
  align-items: left;
  text-align: left;
  overflow: hidden;
  padding: 5%;
}

.extented-testimonies{
  width: 100%;
  height: auto;
  justify-content: left;
  align-items: left;
  text-align: left;
  overflow: hidden;
  padding-left: 5%;
  padding-right: 5%;
}

.title-testimony{
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: x-large; */
}

.name-withness{
  /* font-size: x-large; */
}

.title-withness{
  /* font-size: large; */
}


.subtitles-testimonies{
  height: 20%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* text-size-adjust: 10%; */
  /* font-size: xx-large; */
  color: var(--maincolor);
}

.submit-testimonies{
  color: #333;
  height: 20%;
  width: 80%;
  margin: 10%;
  padding: 5%;
  background-color: var(--secondlightcolor);
  border-color: var(--mainlightcolor);

  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; -webkit-user-select:
  none; -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 50px;
}

.submit-button-testimonies-position{
  /* font-size: x-large; */
  height: 20%;
  width: 80%;
  margin: 10%;
  background-color: var(--mainlightcolor);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow:visible;
}


/* End Profils et Témoignage */


/* Other Page with Text */

.picture-other{
  width: 500px;
  height: 500px;
  max-width: 100%;
  object-fit: contain;
  padding: 0%;
  margin: 0%;
}

.main-description-other{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 70%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align:middle;;
  overflow: hidden;
  position: relative;
  color: var(--maincolor);
}

#main-description-other-special{
  padding-top: 20% !important;
}

#end-box-withness{
  height: 30% !important;
}

.second-description-other{
  /* font-size: large; */
  text-align: justify;
  text-justify: inter-word;
  padding-left: 10%;
  padding-right: 10%;
  color: var(--maincolor);
}

.description-Other{
  /* font-size: x-large; */
  padding: 10%;
  color: var(--mainlightcolor);
}

.double-div-other{
  width: 100%;
  position:relative;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align:middle;
  /* padding-top: 5%; */
}

.double-div-other-1{
  width: 50%;
  float: left;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align:middle;
  overflow: hidden;
  position: relative;
  /* text-size-adjust: 10%; */
  /* font-size: xx-large; */
  /* padding-top: 5%;
  margin-top: 5%; */
}

.double-div-other-1 img{
  width: 500px;
  height: 500px;
  max-width: 100%;
  object-fit: contain;
  /* flex-shrink: 0;
  min-width: 100%;
  min-height: 100%; */
}

.double-div-other-2{
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align:middle;;
  overflow: hidden;
  position: relative;
  /* text-size-adjust: 10%; */
  /* font-size: large; */
}

.double-div-other-2 img{
  width: 500px;
  height: 500px;
  max-width: 100%;
  object-fit: contain;
  /* flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  margin-left: 5%;
  margin-right: 5%; */
}

.section-other{
  width: 100%;
  position:relative;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align:middle;
  margin: 0;
}

.section-other img {
  width: 50%;
  height: 70%;
  max-width: 100%;
  object-fit: contain;
  padding: 0;
  margin: 5%;
  margin-bottom: 0%;
}


#women-description{
  background-color: var(--secondlightcolor) !important;
  border-radius: 0px 50px 50px 0px;
  color: var(--maincolor);
  margin-left: 0%;
  padding: 5%;
}

#ajustment{
  margin: 5%;
}

.five-div-other{
  width: 100%;
  height: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: x-large; */
  padding-bottom: 1%;
}

.five-div-other-1-2-3-4{
  width: 20%;
  float: left;
  background-color: inherit;

}

.five-div-other-5{
  width: 20%;
  float: right;
  background-color: inherit;

}


.tri-div-other{
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 40%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: x-large; */
  padding-top: 0%;
  padding-bottom: 20%;
  margin-top: 0%;
  margin-bottom: 0%;
}

#belief-tri-div{
  height: 60% !important; 
}


#special_tri_div{
  padding-bottom: 10% !important;
  height: 10% !important;
}


.tri-div-other-1-2{
  width: 33%;
  float: left;
  background-color: inherit;
}

.tri-div-other-3{
  width: 33%;
  float: right;
  background-color: inherit;
}

#withness-end-sentence{
  margin-top: 12%;
}

#activite-perso{
  margin-bottom: 0%;
  padding-bottom: 0%;
  height: 20% !important;

}

#full-lenght-generated-text{
  width: 100%;
  height: 100%;
  object-fit:contain;
}

.withness-tri-div-picture{
  width: 300px;
  height: 300px;
  max-width: 100%;
  object-fit: contain;
}


.withness-tri-div-text{
  /* font-size: x-large; */
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0%;
  padding: 0%;
}

.subtitles-others{
  background-color: var(--secondlightcolor) !important;
  /* font-size: xx-large; */
  border-radius: 0px 0px 50px 0px;
  color: var(--maincolor);
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 5%;
  width: 100%;
}

.main-belief-text-block{
  width: 100%;
  height: 30%;
}

#belief-text-block{
  background-color: var(--seconddarkcolor) !important;
  color: var(--mainlightcolor);
  /* font-size: x-large; */
  text-align: justify;
  text-justify: inter-word;
  text-align: center;
  padding: 10%;
  width: 100%;
  height: 100%;
}

.tri-div-title{
  /* font-size: xx-large; */
  padding: 5%;
  padding-bottom: 0%;
}

.tri-div-text{
  /* font-size: large; */
  padding: 5%;
}

#emphasis-text {
  background-color: var(--secondlightcolor);
  color:var(--mainlightcolor);
}

.image-description{
  /* font-size: large; */
  margin-top: 1%;
}

.story-title{
  background-color: var(--secondlightcolor) !important;
  border-radius: 0px 50px 50px 0px;
  color: var(--maincolor);
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 5%;
  width: 100%;
  /* font-size: xx-large; */
}

.ajutement-image{
  padding-bottom: 5%;
}

/* End Other Page with Text */

/* Acceuil */

.mission-description{
  background-color: var(--secondlightcolor) !important;
  border-radius: 0px 0px 50px 50px;
  color: var(--maincolor);
  margin-left: 0%;
  padding: 5%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: 2em; */
}


.tri-div-home{
  background-color: var(--mainlightcolor);
  width: 100%;
  height: 20%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* font-size: x-large; */
  /* padding: 10%; */
}

.tri-div-home-1-2{
  width: 33%;
  float: left;
  background-color: inherit;
}

.tri-div-home-3{
  width: 33%;
  float: right;
  background-color: inherit;
}

#home-text-tri-div{
  height: 200px;
  background-color: var(--mainlightcolor);
}

.carousel-inner{
  height: 800px;
}

/* End Acceuil */


/* Text style */

/* .title{
  background-color: var(--mainlightcolor);
  text-align: center;
  font-size: xx-large;
  width: 100%;
  height: 250px;
  padding: 100px;
} */

.title{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Old */
/* .description{
  text-align: left;
  font-size:large;
  font-style: oblique;
  color:var(--mainlightcolor);
  width: 100%;
  height: 150px;
  padding: 50px;
} */

.description{
  /* font-size:x-large; */
  width: 100%;
  height: 15%;
  padding: 100px;
  top:8px;
  right:16px;
  position: relative;
  color:var(--mainlightcolor);
  background-color: var(--maincolor);
}

.description_about{
    /* font-size:x-large; */
    width: 100%;
    height: 25%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    margin-bottom: 0%;
    /* top:8px;
    right:16px; */
    position: relative;
    /* background-color: var(--mainlightcolor); */
  }

  #family-text-color{
    color: var(--maincolor) !important;
  }


  /*
.description_event{
  height: 80px;
  width: 90%;
  font-size:large;
  color: var(--mainlightcolor);
  padding: 5%;
  margin: 5%;
}

/* Format style */

.section {
  width: 100%;
  height: 200px;
  padding: 10px;
}

.section-title{
  background-color: var(--secondlightcolor);
  position: relative;
  color: var(--maincolor);
  text-align: center;
  /* font-size: x-large; */
  width: 100%;
  height: 100px;
  padding: 30px;

}


/* Others */
/* .button{
  display: inline-block;
  margin-top: 15%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; -webkit-user-select:
  none; -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: var(--mainlightcolor);
  border-color: var(--mainlightcolor);
} */

.card{
  position:absolute;
  width: 400px;
}

.card-body{
  width: 400px;
}




/* Overall Branch Properties */


.overall{
  width: 100%;
  height:2000px;
  position: relative;
}

.intro{
    height: 15%;
    padding: 100px;
    position: relative;
    color:var(--mainlightcolor);
  }

.introbox{
  height: 15%;
  padding: 100px;
  position: relative;
  color:var(--mainlightcolor);
}

.titleO{
  /* font-size:4em; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionO{
  /* font-size:x-large; */
  width: 100%;
  height: 10%;
  padding: 100px;
  top:8px;
  right:16px;
  position: relative;
  background-color: var(--mainlightcolor);
}

.contentO{
  width: 100%;
  height: 70%;
  padding: 100px;
  top:8px;
  right:16px;
  position: relative;
  background-color: var(--mainlightcolor);
}

/* .subtitles{
  font-size:3.5em;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--seconddarkcolor);
  height: 10%;
} */


.double-div-section{
  width: 100%;
  padding: 5%;
  position:relative;
  height: 25%;
}
.double-div-section-1{
  width: 50%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* margin-top: 10%; */
  /* text-size-adjust: 10%; */
}

.double-div-section-1 img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  padding: 5%;
  margin: 1%;
}

.double-div-section-2{
  width: 50%;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 1%;
  /* text-size-adjust: 10%; */
}


.double-div-section-2 img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  padding: 5%;
  margin: 1%;
}

.text_overflow_message{
  visibility: hidden;
  color: var(--seconddarkcolor);
}

@media(max-width:1000px)
{
  .double-div-section-1{
    flex-direction:column;
    align-items:flex-start;
    /* text-size-adjust: 5%; */
  }

  .double-div-section-2{
    flex-direction:column;
    align-items:flex-start;
    /* text-size-adjust: 5%; */
  }

  .text_overflow{
    visibility: hidden;
    display: none;
  }

  .text_overflow_message{
    visibility: visible;
    color: var(--seconddarkcolor);
  }
}
/*
.middle_button{
  position:absolute;
  bottom: 10%;
} */

/* Specific Differences */

#book_section{
  height: 50%;
  background: linear-gradient(180deg, var(--maincolor) 50%, var(--mainlightcolor) 50%);
  position: relative;
}

#book_description{
  /* position: absolute; */
  bottom: 0;
  padding: 10%;
  margin: 1%;
  background-color: var(--mainlightcolor);
}
/*
#request{
  width: 100%;
  height: 30%;
  padding: 0%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-size-adjust: 5%;
  background-color: var(--secondlightcolor);
}

#request_question{
  font-size: 3em;
  padding: 0%;
  position: relative;
  height: 40%;
  top: 0%;
} */


/* From Edition */


.request_E{
  width: 100%;
  height: 30%;
  padding: 0%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-size-adjust: 5%; */
  background-color: var(--secondlightcolor);
}

.request_question_E{
  /* font-size: 3em; */
  padding: 0%;
  position: relative;
  height: 40%;
  top: 0%;
}


.middle_button_E{
  position: absolute;
  bottom: 10%;
}

.button_E{
  display: inline-block;
  margin-top: 15%;
  padding: 6px 12px;
  margin-bottom: 0;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; -webkit-user-select:
  none; -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: var(--mainlightcolor);
  border-color: var(--mainlightcolor);
}



/* From House */

.subtitles_H{
  /* font-size:3.5em; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--seconddarkcolor);
  height: 10%;
}


#request_H{
  width: 100%;
  height: 30%;
  padding: 0%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-size-adjust: 5%; */
  background-color: var(--secondlightcolor);
}

#request_question_H{
  /* font-size: 3em; */
  padding: 0%;
  position: relative;
  height: 40%;
  top: 0%;
}


.middle_button_H{
  position:absolute;
  top: 20%;
}

.button_H{
  display: inline-block;
  margin-top: 15%;
  padding: 6px 12px;
  margin-bottom: 0;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; -webkit-user-select:
  none; -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: var(--mainlightcolor);
  border-color: var(--mainlightcolor);
}


/* From Testimonies */



.overall_testimonies{
  width: 100%;
  height:3000px;
  position: relative;
}

.intro_testimonies{
  height: 10%;
  padding: 100px;
  position: relative;
  color:var(--mainlightcolor);
}
/* .description_testimonies{
  font-size:x-large;
  width: 100%;
  height: 10%;
  padding: 90px;
  top:8px;
  right:16px;
  position: relative;
  background-color: var(--mainlightcolor);
} */

.content_testimonies{
  width: 100%;
  height: 80%;
  padding: 90px;
  /* top:8px;
  right:16px; */
  position: relative;
  background-color: var(--mainlightcolor);
}


/* From Contribute */

/*
  #root{
    background-color: var(--maincolor);
  } */
/*
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  } */


/*
.overall{
    width: 100%;
    height:2000px;
    position: relative;
  } */

  /* .intro{
    height: 15%;
    padding: 100px;
    position: relative;
    color:var(--mainlightcolor);
  } */

  /* .title{
    font-size:4em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  /* .description{
    font-size:x-large;
    width: 100%;
    height: 15%;
    padding: 100px;
    top:8px;
    right:16px;
    position: relative;
    background-color: var(--mainlightcolor);
  }
   */
  /* .content{
    width: 100%;
    height: 70%;
    padding: 100px;
    top:8px;
    right:16px;
    position: relative;
    background-color: var(--mainlightcolor);
  } */

  /* .subtitles{
    font-size: xx-large;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--seconddarkcolor);
    height: 10%;
  } */
.App {
  text-align: center;
}

.container{
  background-color: blue;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0%;
  margin: 0%;
}
